import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig();

// Specifikace nepodporovaných browserů
export const browsers = /.*Chrome|Firefox|Safari.*/;

export const statusCode = [401, 403, 503];

export const name = 'x-access-token';
export const logged = 'di-acc-to';

export const recaptchaSiteKey = publicRuntimeConfig.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

export const LOGGED_KEY = 'logged';

export const isBrowser = typeof window !== 'undefined';
