import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, split} from '@apollo/client';
import {ErrorResponse, onError} from '@apollo/client/link/error';
import {getMainDefinition} from '@apollo/client/utilities';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import {useMemo} from 'react';
import fragment from '../../node_modules/@eon.cz/apollo13-graphql-web/lib/fragmentTypesV3.json';
import {LOGGED_KEY, statusCode} from '../common/constants';

declare const process: any;

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const errorLink = () =>
    onError(({networkError}: ErrorResponse) => {
        if (networkError) {
            if (networkError && 'statusCode' in networkError && statusCode.includes(networkError.statusCode)) {
                apolloClient.cache.reset().then(() => {
                    sessionStorage.removeItem(LOGGED_KEY);
                });
                return;
            }
        }
    });

// Polyfill fetch() on the server (used by @apollo/client)
if (!process.browser) {
    global.fetch = fetch;
}

const createClient = (initialState: any | null) => {
    const httpLink: any = new HttpLink({
        uri: `api/graphql`,
        fetchOptions: {credentials: 'same-origin'},
    });

    const link = process.browser
        ? split(({query}) => {
              const definition = getMainDefinition(query);
              return definition.kind === 'OperationDefinition' && (definition.operation === 'query' || definition.operation === 'mutation');
          }, httpLink)
        : httpLink;

    return new ApolloClient({
        connectToDevTools: process.browser,
        ssrMode: !process.browser,
        assumeImmutableResults: false,
        link: ApolloLink.from([errorLink(), link]),
        cache: new InMemoryCache({
            possibleTypes: fragment.possibleTypes,
            typePolicies: {
                overitUzivatele: {
                    keyFields: ['jwt'],
                },
            },
        }).restore(initialState || {}) as any,
        defaultOptions: {
            query: {
                fetchPolicy: 'cache-first',
            },
            watchQuery: {
                nextFetchPolicy(lastFetchPolicy) {
                    if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'no-cache') {
                        return 'cache-first';
                    }
                    return lastFetchPolicy;
                },
            },
        },
    });
};

export let apolloClient: ApolloClient<any>;

export const initApollo = (initialState: any | null) => {
    const _apolloClient = apolloClient ?? createClient(initialState);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))],
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
};

export const addApolloState = (client: ApolloClient<any>, pageProps: any) => {
    if (pageProps?.props) {
        pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
};

export const useApollo = (pageProps: any) => {
    const state = pageProps[APOLLO_STATE_PROP_NAME];
    return useMemo(() => initApollo(state), [state]);
};
