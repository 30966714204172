import {ApolloProvider} from '@apollo/client';
import {CacheProvider, EmotionCache} from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {cs} from 'date-fns/locale';
import App, {AppContext, AppProps} from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {CustomFormats, RawIntlProvider, createIntl, createIntlCache} from 'react-intl';
import {ErrorFallback} from '../client/common/components/error/ErrorFallback';
import {browsers} from '../client/common/constants';
import {useApollo} from '../client/lib/apolloClient';
import {AppContextProvider} from '../client/lib/context/AppContext';
import createEmotionCache from '../client/lib/createEmotionCache';
import {useTheme} from '../client/lib/theme';
/* Root stylesheet */
import '../public/static/fonts/BrixSans/stylesheet.css';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// This is optional but highly recommended
// since it prevents memory leak
const intlCache = createIntlCache();

type Props = AppProps & {
    readonly token: string;
    readonly locale: string;
    readonly messages: {readonly [key: string]: string};
    readonly formats: CustomFormats | undefined;
    readonly statusCode: number;
    readonly emotionCache?: EmotionCache;
};

const MyApp = (props: Props) => {
    const {Component, pageProps, messages, locale, formats, statusCode, emotionCache = clientSideEmotionCache} = props;

    const apolloClient = useApollo(pageProps);

    const {theme} = useTheme();

    const {push} = useRouter();

    useEffect(() => {
        if (!browsers.test(window.navigator.userAgent)) {
            push('/warning.html');
        }
    });

    return (
        <ApolloProvider client={apolloClient}>
            <RawIntlProvider value={createIntl({locale, messages, formats}, intlCache)}>
                <CacheProvider value={emotionCache}>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns as any} adapterLocale={cs}>
                            <CssBaseline />
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                <Head>
                                    {/* eslint-disable-next-line i18next/no-literal-string */}
                                    <title>Kontakty pro zasílání notifikací distributora</title>
                                </Head>
                                <AppContextProvider>
                                    <Component {...pageProps} statusCode={statusCode} />
                                </AppContextProvider>
                            </ErrorBoundary>
                        </LocalizationProvider>
                    </ThemeProvider>
                </CacheProvider>
            </RawIntlProvider>
        </ApolloProvider>
    );
};

const getMessages = (locales: string | readonly string[] = ['cs']) => {
    if (!Array.isArray(locales)) {
        locales = [locales as string];
    }
    let langBundle;
    let locale;
    for (let i = 0; i < locales.length && !locale; i++) {
        locale = locales[i];
        switch (locale) {
            case 'cs':
                langBundle = import('../lang/cs.json');
                break;
            case 'en':
                langBundle = import('../lang/en.json');
                break;
            default:
                break;
        }
    }
    if (!langBundle) {
        return ['cs', import('../lang/cs.json')];
    }
    return [locale, langBundle];
};

const getInitialProps = async (appContext: Partial<AppContext>) => {
    const {ctx} = appContext;
    const requestedLocales: string | readonly string[] =
        (ctx?.req as any)?.locale ||
        (typeof navigator !== 'undefined' && navigator.languages) ||
        // IE11
        (typeof navigator !== 'undefined' && (navigator as any).userLanguage) ||
        (typeof window !== 'undefined' && (window as any).LOCALE) ||
        'cs';

    const [supportedLocale, messagePromise] = getMessages(requestedLocales);

    const {formats} = ctx?.req || (window as any).__NEXT_DATA__.props;

    const [messages, appProps] = await Promise.all([messagePromise, App.getInitialProps(appContext as AppContext)]);

    const statusCode = ctx?.res?.statusCode;

    return {
        ...appProps,
        locale: supportedLocale,
        messages: (messages as {readonly default: {readonly [key: string]: string}})?.default,
        formats,
        statusCode,
    };
};

MyApp.getInitialProps = getInitialProps;

export default MyApp;
